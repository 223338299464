import "./App.css";
import { useParallax } from "react-scroll-parallax";

const Spread = ({ children, className = "" }) => (
  <div className={`pure-g spread ${className}`}>{children}</div>
);

function App() {
  const parallax = useParallax({
    onProgressChange: (progress) => {
      const y = Math.max(progress, 0) * 100;

      if (parallax.ref.current && y > 60) {
        // set progress to CSS variable
        parallax.ref.current.style.setProperty("mix-blend-mode", "difference");
        // transform: translateY(-50%);
      } else {
        parallax.ref.current.style.setProperty("mix-blend-mode", "unset");
      }
    },
  });
  return (
    <div className="App">
      <header className="pure-g">
        <div ref={parallax.ref}>
          <h1>
            BE THERE
            <br />
            IN 5 MINUTES.
          </h1>
          <div className="l-box">
            <p>Photography by Hermenegildo Isaias.</p>
          </div>
        </div>
      </header>

      <main>
        <Spread className="punctuality">
          <div className="pure-u-1-2 pure-u-md-1 pure-u-sm-1">
            <h4>
              "My Love-Hate Relationship with <u>punctuality</u>"
            </h4>

            <p>
              A humorous ode to my chronic time management woes and a reminder
              that I'm working on it. This is a work-in-progress zine, but it
              already captures the spirit of my journey and offers a glimpse
              into my ongoing efforts to improve my punctuality. Stay tuned for
              the final print
            </p>

            <div className="cred">
              <p>Cameras:</p>
              <ul>
                <li>Fujifilm X-T2</li>
                <li>Fujifilm X-E2S</li>
              </ul>
            </div>
          </div>

          <div className="pure-u-1-2 pure-u-md-1 pure-u-sm-1">
            <div className="circle-container l-box">
              <img
                className="marked-circle"
                src="./assets/images/manic_circle-05.svg"
                alt="circled in clock"
              />
              <img
                className="pure-img"
                src="./assets/images/clock.jpg"
                alt="man in trench coat walking towards camera"
              />
            </div>
          </div>
        </Spread>
        <Spread className="blue-spread">
          <div className="pure-u-1-2 pure-u-sm-1 ">
            <img
              className="pure-img"
              src="./assets/images/man-walking.jpg"
              alt="reflection of man on moving train windows"
            />
          </div>
          <div className="pure-u-1-2 pure-u-sm-1 excuses">
            <h4>Sorry I'm late</h4>
            <ul>
              <li>
                <s>I lost my keys</s>
              </li>
              <li>
                <s>I missed the bus</s>
              </li>
              <li>
                <s>My uber was late</s>
              </li>
              <li>
                <s>Forgot my phone</s>
              </li>
            </ul>
            <p>
              I changed my outfit <br />{" "}
              <span className="scribble-circle">5 TIMES</span>
            </p>
          </div>
        </Spread>

        <Spread className="station-sitting">
          <div className="pure-u-1 pure-u-sm-1">
            <div className="l-box">
              <span className="overlay">
                <img
                  className="pure-img"
                  src="./assets/images/woman-sitting.jpg"
                  alt="woman waiting at metro stop"
                />
                <p className="late-text">SHIT, I'M LATE</p>
              </span>
            </div>
          </div>
        </Spread>

        <Spread className="spread-end">
          <div className="pure-u-1 pure-u-sm-1">
            <div className="l-box">
              <span className="overlay">
                <img
                  className="pure-img"
                  src="./assets/images/exit-2.jpg"
                  alt="sao bento porto train station main entrance"
                />
              </span>
            </div>
          </div>
        </Spread>
      </main>
    </div>
  );
}

export default App;
